import React, { Component } from 'react'

export default class Footer extends Component {
  render() {
    return (
      <div id="footer">
        <div>Icons made by <a href="https://www.flaticon.com/authors/freepik" title="Freepik">Freepik</a> from <a href="https://www.flaticon.com/"     title="Flaticon">www.flaticon.com</a></div>
      </div>
    )
  }
}
